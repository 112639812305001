<script>
import { mapState } from 'vuex';
import formVal from '@/plugins/form-validator';
import PhoneInput from '@/components/PhoneInput.vue';

export default {
  name: 'ProfilePage',
  components: {
    PhoneInput,
  },
  data() {
    return {
      details_form: {
        company: null,
        phone: null,
        errors: {
          company: null,
          phone: null,
        },
        timeUntilNextValidation: 90,
      },
      password_form: {
        password: '',
        passwordConf: '',
        errors: {
          password: null,
          passwordConf: null,
        },
      },
      phone_verification_form: {
        code: null,
        error: null,
      },
      user_sub: {
        numLoggers: null,
        quotaPerLogger: null,
        start: null,
        end: null,
      },
      logger_subs: {
        fields: [
          { key: 'serial', sortable: true },
          { key: 'quota', sortable: false, formatter: 'formatFileSize' },
          { key: 'start', sortable: false, formatter: 'formatDate' },
          { key: 'end', sortable: false, formatter: 'formatDate' },
          { key: 'status', sortable: true, formatter: s => (s ? 'Active' : 'Expired') },
        ],
      },
    };
  },
  computed: {
    ...mapState(
      'user',
      [
        'name',
        'email',
        'company',
        'phone',
        'phone_verified',
        'phone_verification_time',
        'user_subscription',
        'logger_subscriptions',
      ],
    ),
    userSubEndVal() {
      return this.user_subscription.end ? this.$d(this.user_subscription.end) : 'No Expiry';
    },
    formattedPhone() {
      if (!this.details_form.phone) return '';
      const formatted = this.details_form.phone.replace(/[^\d|^\+]/g, ''); // eslint-disable-line
      return formatted;
    },
  },
  methods: {
    /**
     * Format a value in bytes to a human readable string
     */
    formatFileSize(bytes) {
      if (bytes < 1024) {
        return `${bytes} bytes`;
      } if (bytes < 1024000) {
        return `${(bytes / 1000).toFixed(2)} kB`;
      } if (bytes < 1024000000) {
        return `${(bytes / 1000000).toFixed(2)} MB`;
      }
      return `${(bytes / 1000000000).toFixed(2)} GB`;
    },
    /**
     * Format the date using the current locale
     */
    formatDate(date) {
      return this.$d(date);
    },
    /**
     * Returns the row style based on the subscription status
     */
    subscriptionRowStyle(item) {
      if (item.status) return 'table-success';
      return 'table-danger';
    },
    /**
     * Use the store to save the user's details
     */
    saveDetails() {
      if (!this.$refs.phoneInput.valid) {
        this.details_form.errors.phone = true;
      }
      const val = formVal.validate(this.details_form, formVal.constraints.user.updateDetails);
      if (val) {
        this.details_form.errors.company = !('company' in val);
        this.details_form.errors.phone = !('phone' in val);
        return;
      }
      this.$store.dispatch('user/updateDetails', {
        company: this.details_form.company,
        phone: this.formattedPhone !== this.phone ? this.formattedPhone : undefined,
      })
        .then(() => {
          this.$bvToast.toast('Updated Successfully!', {
            variant: 'success',
            title: 'Success',
            toaster: 'b-toaster-bottom-center',
          });
        });
    },
    /**
     * Send a phone verification message and open verification window
     */
    sendPhoneVerification() {
      // Show the user phone verification modal
      this.$bvModal.show('user-phone-verification');

      // If the timer is already counting down, don't send another code
      if (
        this.details_form.timeUntilNextValidation < 90
        && this.details_form.timeUntilNextValidation > 0
      ) return;

      // Call the store action to send the verification code
      this.$store.dispatch('user/sendPhoneVerification')
        .then(() => {
          const updateTimer = () => {
            this.details_form.timeUntilNextValidation = Math.round(
              90 - (new Date().getTime() - this.phone_verification_time.getTime()) / 1000,
            );
            setTimeout(updateTimer, 1000);
          };
          setTimeout(updateTimer, 1000);
        })
        .catch(() => {
          this.$bvModal.hide('user-phone.verify');
          this.$bvToast.toast('An error occurred', {
            variant: 'danger',
            title: 'An error occured when requesting verification code',
          });
        });
    },
    /**
     * Validate a phone verification code
     */
    validatePhone(ev) {
      ev.preventDefault();
      this.$store.dispatch('user/verifyPhone', Number.parseInt(this.phone_verification_form.code, 10))
        .then(() => {
          this.$bvModal.hide('user-phone-verify');
          this.$bvToast.toast('Phone verified successfully', {
            variant: 'success',
            title: 'Success',
            toaster: 'b-toaster-bottom-center',
          });
          this.phone_verification_form.code = null;
        })
        .catch(() => {
          this.phone_verification_form.error = false;
        });
    },
    /**
     * Use the store to save the users password
     */
    savePassword() {
      // Check that the password meets requirements
      const val = formVal.validate(this.password_form, formVal.constraints.user.passwordReset);
      if (val) {
        // Validation failed
        this.password_form.errors.password = !('password' in val);
        this.password_form.errors.passwordConf = !('passwordConf' in val);
        return;
      }
      this.password_form.errors.password = true;
      this.password_form.errors.passwordConf = true;
      this.$store.dispatch('user/changePassword', this.password_form.password)
        .then(() => {
          // Success!
          this.$bvToast.toast('Password changed successfully!', {
            variant: 'success',
            title: 'Success',
            toaster: 'b-toaster-bottom-center',
          });
        });
    },
    phoneValidated(val) {
      this.details_form.errors.phone = val.isValid;
    },
  },
  mounted() {
    this.$store.dispatch('user/fetchSubscriptions')
      .catch(() => {
        this.$bvToast.toast('Failed to fetch subscription info', {
          variant: 'danger',
          title: 'Failure',
          toaster: 'b-toaster-bottom-center',
        });
      });
    this.details_form.company = this.company;
    this.details_form.phone = this.phone;
  },
};
</script>

<template>
  <b-container>
    <b-row align-h='center'>
      <b-col cols=12 md=8>

        <!-- User Details Form -->
        <b-row>
          <b-col>
            <b-card title='User Details'>
              <b-form>
                <b-form-group label='Name'
                              description='Please contact support to edit account name'>
                  <b-form-input type='text' v-bind:value='name' disabled />
                </b-form-group>
                <b-form-group label='Email'
                              description='Please contact support to change your email'>
                  <b-form-input type='text' v-bind:value='email' disabled />
                </b-form-group>
                <b-form-group label='Company'>
                  <b-form-input type='text'
                                v-model='details_form.company'
                                v-bind:state='details_form.errors.company' />
                </b-form-group>
                <b-form-group label='Phone Number'
                              :description="$t('phone_format')">
                  <b-input-group>
                    <phone-input ref='phoneInput'
                                 v-model='details_form.phone'
                                 @validate='phoneValidated' />
                    <b-input-group-append>
                      <b-btn v-if='phone_verified' variant='success' disabled>Verified!</b-btn>
                      <b-btn v-else
                             variant='danger'
                             @click='sendPhoneVerification()'
                             v-b-modal.user-phone-verify
                             v-bind:disabled='formattedPhone !== phone'>
                        Verify
                      </b-btn>
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback v-bind:state='details_form.errors.phone'>
                    Please enter a valid phone number for the selected country.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-btn variant='secondary' @click='saveDetails()'>Save</b-btn>
              </b-form>
            </b-card>
          </b-col>
        </b-row>

        <!-- Password Reset Form -->
        <b-row class='mt-4'>
          <b-col>
            <b-card title='Change Password'>
              <b-form>
                <b-form-group label='New Password'
                              :description="$t('password_requirements')">
                  <b-input type='password'
                           v-model='password_form.password'
                           v-bind:state='password_form.errors.password' />
                </b-form-group>
                <b-form-group label='Confirm Password' >
                  <b-input type='password'
                           v-model='password_form.passwordConf'
                           v-bind:state='password_form.errors.passwordConf' />
                  <b-form-invalid-feedback>
                    Passwords must match
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-btn variant='secondary' @click='savePassword()'>
                  Save
                </b-btn>
              </b-form>
            </b-card>
          </b-col>
        </b-row>

        <!-- Subscriptions Form -->
        <b-row v-if='logger_subscriptions.length > 0' class='mt-4 mb-4'>
          <b-col>
            <b-card title='Logger Subscriptions'>
              <b-table :items='logger_subscriptions'
                       :fields='logger_subs.fields'
                       sort-by='status'
                       :tbody-tr-class="subscriptionRowStyle"
                       sort-desc
                       responsive
                       hover
                       striped
                       sort-icon-left />
            </b-card>
          </b-col>
        </b-row>

        <!-- User Subscriptions Form -->
        <b-row v-if='user_subscription' class='mt-4 mb-4'>
          <b-col>
            <b-card title='User Subscription'>
              <b-form>
                <b-form-group label='Logger Quota'
                              description="Number of loggers you can have on your account">
                  <b-input type='text'
                           v-bind:value="user_subscription.numLoggers || 'unlimited'"
                           disabled />
                </b-form-group>
                <b-form-group label='File Quota'
                              description="File quota for each logger">
                  <b-input type='text'
                           v-bind:value="user_subscription.quotaPerLogger || 'unlimited'"
                           disabled />
                </b-form-group>
                <b-form-group label='Start' >
                  <b-input type='text' v-bind:value='$d(user_subscription.start)' disabled />
                </b-form-group>
                <b-form-group label='End'>
                  <b-input type='text'
                           v-bind:value="userSubEndVal"
                           disabled />
                </b-form-group>
              </b-form>
            </b-card>
          </b-col>
        </b-row>

      </b-col>
    </b-row>

    <!-- Phone Verification Modal -->
    <b-modal id='user-phone-verify' title='Verify Phone Number' @ok='validatePhone'>
      <p class='lead'>A 6-digit code has been sent to {{ phone }}.</p>
      <b-form-group label='Please enter the code below'>
        <b-input type=number max='999999' min='0' v-model='phone_verification_form.code' />
        <b-form-invalid-feedback>
          That didn't work. Please check you entered the code correctly.
        </b-form-invalid-feedback>
        <b-form-text>
          <b-link v-bind:disabled='details_form.timeUntilNextValidation >= 0'
                  @click="sendPhoneVerification()">
            Resend Code
            <span v-if='details_form.timeUntilNextValidation > 0'>
              (Wait {{ details_form.timeUntilNextValidation }} seconds)
            </span>
          </b-link>
        </b-form-text>
      </b-form-group>
    </b-modal>
  </b-container>
</template>

<style scoped>
  a.disabled {
    pointer-events: none;
  }
</style>
